export const addAnalytics = (id) => {

  if (id) {

    let analyticsHtml = "\n" + "window.dataLayer = window.dataLayer || [];" + "\n";
    analyticsHtml += "function gtag(){dataLayer.push(arguments);}" + "\n";
    analyticsHtml += "gtag('js', new Date());" + "\n";
    analyticsHtml += "gtag('config', '" + id + "');" + "\n";

    let scripts = [
      {
        src: "https://www.googletagmanager.com/gtag/js?id=" + id,
        async: true
      },
      {
        innerHTML: analyticsHtml
      },
    ];

    useHead({
      script: scripts
    });
  }
};

export const triggerPurchaseEvent = (data, store) => {

  let country = store.siteSettings.site_title + ' - ' + store.siteSettings.site.country_name;
  let currency = store.siteSettings.currency.code;

  let eventData = {
      "transaction_id": data.order_id,
      "value": data.total,
      "tax": 0,
      "shipping": 0,
      "currency": currency,
      "coupon": "",
      "items": [
          {
              "item_id": data.test.code,
              "item_name": data.test.title,
              "affiliation": country,
              "coupon": "",
              "discount": 0,
              "index": 0,
              "item_brand": "",
              "item_category": "",
              "item_category2": "",
              "item_category3": "",
              "item_category4": "",
              "item_category5": "",
              "item_list_id": "",
              "item_list_name": "",
              "item_variant": "",
              "location_id": "",
              "price": data.test.price,
              "quantity": 1,
          },
      ]
  };

  let analyticsHtml = "\n" + "gtag('event', 'purchase', " + JSON.stringify(eventData) + ");" + "\n";

  let scripts = [
    {
      innerHTML: analyticsHtml
    },
  ];

  useHead({
    script: scripts
  });
};

export const addGTMAnalytics = (id) => {

  if (id) {

    let analyticsHtml = "\n" + "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':" + "\n";
    analyticsHtml += "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0]," + "\n";
    analyticsHtml += "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" + "\n";
    analyticsHtml += "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);" + "\n";
    analyticsHtml += "})(window,document,'script','dataLayer','" + id + "');" + "\n";

    let bodyHtml = '<iframe src="https://www.googletagmanager.com/ns.html?id=' + id + '" height="0" width="0" style="display:none;visibility:hidden"></iframe>';

    let scripts = [
      {
        innerHTML: analyticsHtml
      },
    ];

    let noscript = [
      {
        innerHTML: bodyHtml,
        tagPosition: 'bodyOpen'
      },
    ];

    useHead({
      script: scripts,
      //noscript: noscript,
    });
  }
};